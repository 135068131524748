// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-tsx": () => import("../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-category-tsx": () => import("../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-subcategory-tsx": () => import("../src/templates/subcategory.tsx" /* webpackChunkName: "component---src-templates-subcategory-tsx" */),
  "component---src-templates-page-tsx": () => import("../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cancel-tsx": () => import("../src/pages/cancel.tsx" /* webpackChunkName: "component---src-pages-cancel-tsx" */),
  "component---src-pages-checkout-tsx": () => import("../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-2-tsx": () => import("../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-search-tsx": () => import("../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-story-tsx": () => import("../src/pages/story.tsx" /* webpackChunkName: "component---src-pages-story-tsx" */),
  "component---src-pages-success-tsx": () => import("../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */)
}

